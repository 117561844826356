<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>{{ param.headerTitle }}</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">            
            <v-form ref="form" v-model="valid" lazy-validation>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">메시지분류</label></th>
                            <td>
                                <v-select  
                                    class="jh-form is-md"
                                    :items="dropItems2" 
                                    item-text="name"
                                    item-value="value"
                                    v-model="paramData.NEW_MSG_CL_NAME"
                                    outlined required
                                    :rules="validateRules.newMsgClName"
                                    placeholder="선택하세요"
                                ></v-select>
                            </td>
                            <th><label></label></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th><label class="is-required">메시지제목</label></th>
                            <td colspan="3">
                                <v-text-field class="jh-form is-md" name="NEW_MSG_DESC" v-model="paramData.NEW_MSG_DESC" maxlength="20" :rules="validateRules.newMsgDesc" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">메시지내용</label></th>
                            <td colspan="3">
                                <v-textarea class="jh-form" v-model="paramData.NEW_MSG_CONTENT" min="500" :rules="validateRules.newMsgContent" outlined required></v-textarea>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">사용여부</label></th>
                            <td>
                                <v-select  
                                    class="jh-form is-md"
                                    :items="mixin_common_code_get(this.common_code, 'TWB001','선택')" 
                                    v-model="paramData.NEW_USE_YN"
                                    :rules="validateRules.newUseYn"
                                    outlined required
                                ></v-select>
                            </td>
                            <th><label class="is-required">등록자</label></th>
                            <td>
                                <v-text-field class="jh-form is-md is-trns" name="userId" v-model="REG_ID" maxlength="20" outlined required disabled></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">메시지시간</label></th>
                            <td>
                                <v-text-field type="number" class="jh-form is-md" name="NEW_MSG_TIME" id="sortOrd" min="0" max="9999" v-model.number.lazy="paramData.NEW_MSG_TIME" :rules="validateRules.newMsgTime" outlined required></v-text-field>
                            </td>
                            <th><label></label></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th><label class="is-required">메시지타입</label></th>
                            <td>
                                <v-select  
                                    class="jh-form is-md"
                                    :items="dropItems" 
                                    item-text="name"
                                    item-value="value"
                                    name="NEW_MSG_TYPE"
                                    v-model="paramData.NEW_MSG_TYPE"
                                    :rules="validateRules.newMsgType"
                                    outlined required
                                    @change="changeDrop1"
                                    placeholder="선택"
                                ></v-select>
                            </td>
                            <th><label class="is-required" v-show="showLinkType">링크타입</label></th>
                            <td>
                                <v-select  
                                    v-show="showLinkType"
                                    class="jh-form is-md"
                                    :items="dropItems4" 
                                    item-text="name"
                                    item-value="value"
                                    v-model="paramData.NEW_LINK_TYPE"
                                    :rules="validateRules.NEW_LINK_TYPE"
                                    outlined required
                                    @change="changeDrop2"
                                    placeholder="선택"
                                ></v-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- 웹링크 -->
                <div class="is-mt-10" v-show="showWeblinkType">
                    <div class="jh-ui-header">
                        <h3>웹링크</h3>
                    </div>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="100px">
                            <col>
                            <col width="100px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label class="is-required">버튼명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" id="id" v-model="BTN_NM" outlined required></v-text-field>
                                </td>
                                <th><label class="is-required">사용여부</label></th>
                                <td>
                                    <v-select  
                                        class="jh-form is-md"
                                        :items="mixin_common_code_get(this.common_code, 'TWB001','선택')" 
                                        v-model="USE_YN"
                                        outlined required
                                    ></v-select>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">MOBILE URL</label></th>
                                <td colspan="3">
                                    <v-text-field class="jh-form is-md" name="name" id="id" v-model="URL_MOBILE" outlined required></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>PC URL</label></th>
                                <td colspan="3">
                                    <v-text-field class="jh-form is-md" name="name" id="id" v-model="URL_PC"></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 키워드 -->
                <div class="is-mt-10" v-show="showKeywordType">
                    <div class="jh-ui-header">
                        <h3>키워드</h3>
                    </div>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="100px">
                            <col>
                            <col width="100px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label class="is-required">버튼명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" id="id" v-model="BTN_NM" outlined required></v-text-field>
                                </td>
                                <th><label class="is-required">사용여부</label></th>
                                <td>
                                    <v-select  
                                        class="jh-form is-md"
                                        :items="mixin_common_code_get(this.common_code, 'TWB001','선택')" 
                                        v-model="USE_YN"
                                        outlined required
                                    ></v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-form>
        </div>
        <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
            <v-btn class="jh-btn is-md is-main" @click="submitbtn">저장</v-btn>
        </div>
    </div>
</template>
<script>
import api from "../../store/apiUtil";
import {mixin} from "../../mixin/mixin";
export default {
  name: "MENU_E020604P02", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
  },
  mixins:[mixin],
  data(){
    return {
      common_code: [],
      newMsgClName:'',
      groupCd:'',
      NEW_LINK_TYPE:'',
      URL_MOBILE:'',
      URL_PC:'',
      newMsgDesc:'',
      newMsgContent:'',
      NEW_MSG_CL_NAME:'',
      BTN_NM:'',
      USE_YN:'',
      newUseYn:'',
      newMsgType:'',
      REG_ID : this.$store.getters['userStore/GE_USER_ROLE'].userName,
      menu1: false,
      menu2: false,
      date: {},
      date2: {},
      valid: true,
      validateRules: {
        newMsgClName: [
          v => !!v || '메시지분류명은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        newMsgDesc: [
          v => !!v || '시스템메시지명은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        newMsgContent     : [
          v => !!v || '시스템메시지는 필수입니다.',
        ],
        newUseYn       : [
          v => !!v || '사용여부 필수입니다.',
        ],
        newMsgType  : [
          v => !!v || '시스템메시지타입은 필수입니다.',
        ],
       /* NEW_LINK_TYPE  : [
          v => !!v || '링크타입은 필수입니다.',
        ],*/
        newMsgTime: [
          v => /[0-9]/.test(v) || '메시지시간은 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
      },
      footerHideTitle: "닫기",
      dropItems2 : [
        {name: "자동응답메시지",value:"04"},
        {name: "응대지연메시지",value:"01"},
        {name: "고객무응답메시지",value:"02"},
        {name: "접수지연메시지",value:"03"},
      ],
      dropItems3: [],
      dropItems:[
        {name: "선택",value:""},
        {name: "일반메시지",value:"TX"},
        {name: "링크메시지",value:"LI"},
      ],
      dropItems4:[
        { name: "선택", value:"" },
        { name: "웹링크", value:"WL" },
        { name: "키워드", value:"BK" },
      ],
      showLinkType: false,
      showKeywordType: false,
      showWeblinkType: false,
      SYSTEM_MSG_LINKS_ID: ""
    }
  },
  methods:{
    async init(){
      let type = this.param.type;
      let codeName = ["TALK017","TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
      if(type == "R"){//상세
        this.is = {
          newMsgDesc: false,
          newMsgContent: false,
          newUseYn: false,
          newRegdName: false,
          newMsgTime: false,
          newUpdId:false,
          newUpdName:false,
          newMsgTimeOrg:false,
          strDivId:false,
        };
      }
      this.paramData.NEW_MSG_TIME = 0;
    },
    removeChar(event){
      let num = Number(event.target.value);
      if(isNaN(num)){
        this.paramData.NEW_MSG_TIME = 0;
      }else{
        this.paramData.NEW_MSG_TIME = num;
      }
    },
    restoreXSS_Dec2(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();
      //문자열 길이가 4이상일 경우만 처리
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}
      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      //strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      //strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      return strText;
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    async submitbtn() {
      if(!this.$refs.form.validate()) return false
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/innb/common/generate-unique-no/inqry";
      requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"] = false;
      requestData.headers["INQRY"] = "BASIC";
      requestData.sendData = {};
      // sendData 세팅
      requestData.sendData["BIZ_CASE"] = "M";//제목
      let response = await this.common_postCall(requestData);
      this.submitbtnCallBack(response);
    },
    submitbtnCallBack(response){
      let data = response.DATA;
      if(this.paramData.NEW_MSG_TYPE == "TX"){//일반메시지
        this.submit(data)
      }else if(this.paramData.NEW_MSG_TYPE == "LI" ){//링크메시지_키워드
        this.linkSubmit(data);
      }
    },
    async submit(param){//일반메시지저장
      let requestData = {
          headers: {
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };
        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/system-mssage-manage/regist";
        requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        requestData.sendData = {};
        for (let prop in this.paramData) {
          requestData.sendData[prop] = this.paramData[prop];
        }
        // sendData 세팅
        requestData.sendData["NEW_MSG_DESC"] = this.paramData.NEW_MSG_DESC;//제목
        let msg = this.restoreXSS_Dec2(this.paramData.NEW_MSG_CONTENT);
        requestData.sendData["NEW_MSG_CONTENT"] = msg;//내용
        requestData.sendData["NEW_REG_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
        requestData.sendData["NEW_DEPT_CODE"] ="x";
        requestData.sendData["NEW_MSG_TYPE"] = "TX";
        requestData.sendData["NEW_LINK_TYPE"] = this.paramData.NEW_LINK_TYPE;
        requestData.sendData["NEW_REG_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
        requestData.sendData["NEW_USE_YN"] = this.paramData.NEW_USE_YN;
        requestData.sendData["NEW_MSG_TIME"] = this.paramData.NEW_MSG_TIME;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;
        requestData.sendData["SYSTEM_MSG_ID"] = param[0].RET_VAL;

        let response = await this.common_postCall(requestData);
        this.submitCallBack(response);
    },
    submitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.common_alert('정상처리 되었습니다.', 'done');
      this.$emit('hideAndGetList');
    },
    async linkSubmit(){//링크메시지 저장
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/innb/common/generate-unique-no/inqry";
      requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"] = false;
      requestData.headers["INQRY"] = "LNK";
      requestData.sendData = {};
      // sendData 세팅
      requestData.sendData["BIZ_CASE"] = "LNK";//제목
      let response = await this.common_postCall(requestData);
      this.linkSubmitCallBack(response);
    },
    linkSubmitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      if(this.paramData.NEW_MSG_TYPE == "LI" && this.paramData.NEW_LINK_TYPE == "BK"){//링크메시지_키워드
        this.linkSubmitBtn(data);
      }else if(this.paramData.NEW_MSG_TYPE == "LI" && this.paramData.NEW_LINK_TYPE == "WL"){//링크메시지_웹링크
        this.webLinkSubmitBtn(data);
      }
    },
    async linkSubmitBtn(param){//키워드저장
      if(this.BTN_NM== undefined || this.BTN_NM == ''){
        this.common_alert("링크버튼명은 필수 입력값 입니다.","error");
      }else if(this.USE_YN == undefined || this.USE_YN == ''){
        this.common_alert("사용여부는 필수 입력값 입니다.","error");
      }else {

        //메시지타입 : 링크메시지로 생성 할 경우
        this.SYSTEM_MSG_LINKS_ID = await this.mixin_common_bizSeq_get('LNK');
        let DATA_OBJECT = [];
        let OBJECT = {
          SYSTEM_MSG_LINKS_ID: this.SYSTEM_MSG_LINKS_ID,
          BTN_NM: this.BTN_NM,
          USE_YN: this.USE_YN,
        }
        DATA_OBJECT.push(OBJECT)
        let requestData = {
          headers: {
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };
        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/system-mssage-manage/regist";
        requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;
        requestData.headers["DATA_OBJECT"] = "LINKDATA";

        requestData.sendData = {};
        for (let prop in this.paramData) {
          requestData.sendData[prop] = this.paramData[prop];
        }
        // sendData 세팅
        requestData.sendData["NEW_MSG_DESC"] = this.paramData.NEW_MSG_DESC;//제목
        let msg = this.restoreXSS_Dec2(this.paramData.NEW_MSG_CONTENT);
        requestData.sendData["NEW_MSG_CONTENT"] = msg;//내용
        requestData.sendData["NEW_REG_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
        requestData.sendData["NEW_DEPT_CODE"] = "x";
        requestData.sendData["NEW_MSG_TYPE"] = this.paramData.NEW_MSG_TYPE;
        requestData.sendData["NEW_LINK_TYPE"] = "BK";
        requestData.sendData["NEW_REG_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
        requestData.sendData["NEW_USE_YN"] = this.paramData.NEW_USE_YN;
        requestData.sendData["NEW_MSG_TIME"] = this.paramData.NEW_MSG_TIME;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;
        requestData.sendData["SYSTEM_MSG_ID"] = param[0].RET_VAL;
        requestData.sendData["LINKDATA"] = DATA_OBJECT;

        let response = await this.common_postCall(requestData);
        this.linkSubmitBtnCallBack(response);
      }
    },
    linkSubmitBtnCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.common_alert('정상처리 되었습니다.', 'done');
      this.$emit('hideAndGetList');
    },
    async webLinkSubmitBtn(param){//웹링크 저장
        //메시지타입 : 링크메시지로 생성 할 경우
      if(this.BTN_NM== undefined || this.BTN_NM == ''){
        this.common_alert("링크버튼명은 필수 입력값 입니다.","error");
      }else if(this.USE_YN == undefined || this.USE_YN == ''){
        this.common_alert("사용여부는 필수 입력값 입니다.","error");
      }else if(this.URL_MOBILE == undefined || this.URL_MOBILE == ''){
        this.common_alert("모바일 경로는 필수 입력값 입니다.","error");
      } else{

        this.SYSTEM_MSG_LINKS_ID = await this.mixin_common_bizSeq_get('LNK');
        let DATA_OBJECT = [];
        let OBJECT = {
          SYSTEM_MSG_LINKS_ID : this.SYSTEM_MSG_LINKS_ID,
          BTN_NM:this.BTN_NM,
          USE_YN:this.USE_YN,
          URL_MOBILE:this.URL_MOBILE,
          URL_PC:this.URL_PC,
        }
        DATA_OBJECT.push(OBJECT)
        let requestData = {
          headers: {
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/system-mssage-manage/regist";
        requestData.headers["SERVICE"] = "chat.setting.system-mssage-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;
        requestData.headers["DATA_OBJECT"] = "LINKDATA";

        requestData.sendData = {};
        for(let prop in this.paramData){
          requestData.sendData[prop] = this.paramData[prop];
        }
        // sendData 세팅
        requestData.sendData["NEW_MSG_DESC"] = this.paramData.NEW_MSG_DESC;//제목
        let msg = this.restoreXSS_Dec2(this.paramData.NEW_MSG_CONTENT);
        requestData.sendData["NEW_MSG_CONTENT"] = msg;//내용
        requestData.sendData["NEW_REG_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
        requestData.sendData["NEW_DEPT_CODE"] ="x";
        requestData.sendData["NEW_MSG_TYPE"] = this.paramData.NEW_MSG_TYPE;
        requestData.sendData["NEW_LINK_TYPE"] = "WL";
        requestData.sendData["NEW_REG_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
        requestData.sendData["NEW_USE_YN"] = this.paramData.NEW_USE_YN;
        requestData.sendData["NEW_MSG_TIME"] = this.paramData.NEW_MSG_TIME;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;
        requestData.sendData["SYSTEM_MSG_ID"] = param[0].RET_VAL;
        requestData.sendData["LINKDATA"] = DATA_OBJECT;

        let response = await this.common_postCall(requestData);
        this.webLinkSubmitBtnCallBack(response);
      }
    },
    webLinkSubmitBtnCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.common_alert('정상처리 되었습니다.', 'done');
      this.$emit('hideAndGetList');
      //this.linkSubmit();
    },
    async result(requestData){
      let head='';
      let dataa='';
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              // string으로 넘어올 경우 에러임.
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
           /* if(response.data.HEADER.METHOD === "inqry" &&response.data.HEADER.INQRY ==="BASIC"){
              this.submitbtnCallBack(response.data);
            }else if(response.data.HEADER.METHOD === "inqry" &&response.data.HEADER.INQRY ==="LNK"){
              this.linkSubmitCallBack(response.data);
            }*/
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }); //api호출 에러 작업 수행
    },
    changeDrop1(e) {
      if( e ===  'LI' ) {
        this.showLinkType = true;
      } else {//TX
        this.showLinkType = false;
        this.showWeblinkType = false;
        this.showKeywordType = false;
      }
    },
    changeDrop2(e) {
      if( e ===  'BK' ) {
        this.showKeywordType = true;
        this.showWeblinkType = false;
      } else if( e ===  'WL' ) {
        this.showKeywordType = false;
        this.showWeblinkType = true;
      } else {
        this.showWeblinkType = false;
        this.showKeywordType = false;
      }
    }
  },

  mounted(){
    this.init();

  },
  computed: {
    initHeaders(){
      return {
        SERVICE: '',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
        DATA_OBJECT:'',
      };
    },
    paramData(){
      return {
        ASP_NEWCUST_KEY : this.param.ASP_NEWCUST_KEY,
        NEW_MSG_DESC: "",
        NEW_MSG_CONTENT: "",
        NEW_MSG_TIME: "",
        SYSTEM_MSG_ID: this.param.data.SYSTEM_MSG_ID,
        NEW_DEPT_CODE: "x",
        NEW_REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        NEW_REG_NAME: this.$store.getters['userStore/GE_USER_ROLE'].userName,
        NEW_USE_YN: this.param.data.USE_YN,
      };
    }
  },
};
</script>

<style></style>
